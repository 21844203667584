import {useEffect, useState} from 'react';

type UseIsClientResponse = {
  isClient: boolean;
  key: 'client' | 'server';
};

const useIsClient = (): UseIsClientResponse => {
  const [isClient, setClient] = useState(false);
  const key = isClient ? 'client' : 'server';

  useEffect(() => {
    setClient(true);
  }, []);

  return {isClient, key};
};

export default useIsClient;
