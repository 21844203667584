import {Box, Flex, Heading, Text} from '@chakra-ui/react';
import 'globals/styles/wordpress.scss';
import {rgba} from 'polished';

type BannerProps = {
  heading: string;
  content?: string | JSX.Element;
  children?: React.ReactNode;
};

export default function Banner(props: BannerProps): JSX.Element {
  const {heading, content, children} = props;

  return (
    <Box as='section' sx={styles.section}>
      <Box sx={styles.bannerContent}>
        <Flex justifyContent={'flex-start'}>
          <Flex direction={'column'}>
            <Heading style={{marginBottom: '1rem'}}>{heading}</Heading>
            {typeof content === 'string' ? (
              <Text as='p' sx={styles.desc} dangerouslySetInnerHTML={{__html: content}} />
            ) : null}
            {typeof content !== 'string' ? content : null}
            {children ? children : null}
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
}

const styles = {
  section: {
    //clipPath: 'polygon(0 2%, 100% 0, 100% 100%, 0% 100%)',
    'backgroundSize': ['100%', null, null, null, 'cover'],
    'width': '100%',

    'border': '1px solid transparent',

    '@media only screen and (max-width: 1024px)': {
      //maxWidth: 515,
      mt: 0,
      margin: '0',
      padding: '0',
    },
  },

  bannerContent: {
    'backgroundColor': rgba('#fff', 0.75),
    'margin': '1rem',
    'boxShadow': ['0px 10px 16px rgba(52, 61, 72, 0.12)', null, null, null, 'none'],
    //'maxWidth': [null, null, null, 600, 1250, null, 650],
    'padding': ['20px', '30px', null, null, null, '30px 50px 60px', '50px 60px 90px'],
    'borderRadius': 0,

    '@media only screen and (min-height: 720px) and (max-height: 760px), (min-width: 1501px) and (max-width: 1560px) ':
      {
        mt: 70,
        padding: '30px 50px 65px',
      },
  },

  desc: {
    'fontSize': [15, 16, 15, 17],
    'lineHeight': [1.53, null, null, 2, 2.4, 2, 2.48],

    'marginTop': [15, null, null, null, null, null, 30],
    '@media only screen and (min-height: 720px) and (max-height: 760px), (min-width: 1501px) and (max-width: 1560px) ':
      {
        mt: 15,
      },
  },
};
