import Layout from 'components/layout';
import SEO from 'components/seo';

import bannerBgSVG from 'assets/images/gallery/smaller_shutterstock_1389573218.svg';
import useIsClient from 'hooks/useIsClient';
import Banner from 'sections/Banner/Banner';
import styled from 'styled-components';

const BackGround = styled.div`
  background: url(${bannerBgSVG}) no-repeat center top / cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
`;

const Impressum = styled.div`
  @media (min-width: 1024px) {
    max-width: 75%;
  }

  p {
    margin-bottom: 1.75rem;

    a {
      text-decoration: underline;
    }
  }
`;

export default function ImpressumPage(): JSX.Element | null {
  const {isClient} = useIsClient();

  if (!isClient) {
    return null;
  }

  const content = (
    <Impressum>
      <strong>Angaben gemäß &#167; 5 TMG</strong>
      <br />
      <br />
      <p>
        Oliver Kinderknecht <br />
        Outdoor Digital Kinderknecht <br />
        Saleuxerstr. 7 <br />
        D-73563 Mögglingen <br />
      </p>
      <p>
        <strong>Kontakt</strong> <br />
        Telefon: 0049 (0) 7174 296 00 53
        <br />
        E-Mail: <a href='mailto:hallo@outdoor-digital.de'>hallo@outdoor-digital.de</a>
      </p>
      <p>
        <strong>Umsatzsteuer-ID</strong> <br />
        Umsatzsteuer-Identifikationsnummer gemäß &#167;27a Umsatzsteuergesetz: <br />
        Gemäß § 19 Abs. 1 UStG wird keine Umsatzsteuer erhoben.
      </p>
      <p>
        <strong>EU-Streitschlichtung</strong>
        <br />
        Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS)
        bereit:{' '}
        <a href='https://ec.europa.eu/consumers/odr/' target='_blank' rel='noopener noreferrer'>
          https://ec.europa.eu/consumers/odr/
        </a>
        <br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
        <br />
      </p>
      <p>
        <strong>Verbraucherstreitbeilegung/Universalschlichtungsstelle</strong>
        <br />
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
        Verbraucherschlichtungsstelle teilzunehmen.
        <br />
      </p>
      <p>
        <strong>Haftungsausschluss: </strong>
        <br />
        <br />
        <strong>Haftung für Inhalte</strong>
        <br />
        <br />
        Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit,
        Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als
        Diensteanbieter sind wir gemäß &#167; 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach
        den allgemeinen Gesetzen verantwortlich. Nach &#167;&#167; 8 bis 10 TMG sind wir als
        Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
        Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
        Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
        Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche
        Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
        möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte
        umgehend entfernen.
        <br />
        <br />
        <strong>Haftung für Links</strong>
        <br />
        <br />
        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen
        Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.
        Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
        Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf
        mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung
        nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
        konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
        Rechtsverletzungen werden wir derartige Links umgehend entfernen.
        <br />
        <br />
        <strong>Datenschutz</strong>
        <br />
        <br />
        Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich.
        Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder
        eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis.
        Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben. <br />
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per
        E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff
        durch Dritte ist nicht möglich. <br />
        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte
        zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien
        wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich
        rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
        Spam-Mails, vor.
        <br />
        <br />
        <br />
        <strong>Google Analytics</strong>
        <br />
        <br />
        Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. (`Google`).
        Google Analytics verwendet sog. `Cookies`, Textdateien, die auf Ihrem Computer gespeichert
        werden und die eine Analyse der Benutzung der Website durch Sie ermöglicht. Die durch den
        Cookie erzeugten Informationen über Ihre Benutzung dieser Website (einschließlich Ihrer
        IP-Adresse) wird an einen Server von Google in den USA übertragen und dort gespeichert.
        Google wird diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um
        Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um
        weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu
        erbringen. Auch wird Google diese Informationen gegebenenfalls an Dritte übertragen, sofern
        dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google
        verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit anderen Daten der Google in
        Verbindung bringen. Sie können die Installation der Cookies durch eine entsprechende
        Einstellung Ihrer Browser Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in
        diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website voll umfänglich nutzen
        können. Durch die Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über Sie
        erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor
        benannten Zweck einverstanden.
      </p>
      <br />
      <p>
        Impressum vom <a href='https://www.impressum-generator.de'>Impressum Generator</a> der{' '}
        <a href='https://www.kanzlei-hasselbach.de/'>
          Kanzlei Hasselbach, Rechtsanwälte für Arbeitsrecht und Familienrecht
        </a>
      </p>
    </Impressum>
  );

  return (
    <>
      <BackGround className='BackGround_BackGround' />
      <Layout>
        <>
          <SEO title='Impressum' />
          <Impressum>
            <Banner heading='Impressum' content={content} />
          </Impressum>
        </>
      </Layout>
    </>
  );
}
